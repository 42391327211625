import React, { useState, useEffect, useRef } from "react";
// import FilterButton from "../components/FilterButton";
import MemberList from "../components/MemberList";

function Homepage() {
    const [profiles, setProfiles] = useState([]);
    const [DataisLoaded, setLoaded] = useState(false);
    const fullList = useRef([]);
    const [salesactive, setSalesactive] = useState(false);
    const [rentactive, setRentactive] = useState(false);

    const sortJSON = (data) => {
        return data.sort(function(a, b) {
            if(parseInt(a["toolset-meta"]["melcorp-teams"]["order"]["raw"]) < parseInt(b["toolset-meta"]["melcorp-teams"]["order"]["raw"])) {
                return 1;
            }
            if(parseInt(a["toolset-meta"]["melcorp-teams"]["order"]["raw"]) >= parseInt(b["toolset-meta"]["melcorp-teams"]["order"]["raw"])) {
                return -1;
            }
            return 0;
        });
    };

    useEffect(() => {
        fetch('https://melcorprealestate.com.au/wp-json/wp/v2/melcorp-team?per_page=40')
            .then(res => res.json())
            .catch(err => console.log(err))
            .then((json) => {
                json = sortJSON(json);
                const activeMember = json.filter((data) => { return data.status.includes('publish')});       
                setProfiles(activeMember);
                setLoaded(true);
                fullList.current = activeMember;
            });
    }, []);
    let filterTimeOut; //debouncing
    const handleSearch = (query) => {
        clearTimeout(filterTimeOut); // debouncing
        if (query !== '') {
            filterTimeOut = setTimeout(() => { // debouncing
                let filteredItems = fullList.current.filter((data) => {
                    return data["toolset-meta"]["melcorp-teams"]["first-name"]["raw"].toLowerCase().includes(query.toLowerCase());
                });
                if(filteredItems.length) {
                    setProfiles(filteredItems);
                }
            }, 500);
            // }
        } else {
            setProfiles(fullList.current);
        }
    }
    const filterAgent = (query) => {
            let filteredItems = fullList.current.filter((data) => {
                if(query === 'Sales') {
                    setRentactive(false);
                    setSalesactive(true);
                } else if(query === 'Rental') {
                    setRentactive(true);
                    setSalesactive(false);
                }
                return data["toolset-meta"]["melcorp-teams"]["department"]["raw"].toLowerCase().includes(query.toLowerCase());
            });
            setProfiles(filteredItems);
    }
    const clearField = () => {
        document.getElementById("search-input").value = "";
        handleSearch("");
        setRentactive(false);
        setSalesactive(false);
    }

    if(!profiles.length) 
        return <div className='container loading-container'>
            <div className='loader'>
            <p>Loading agents</p>
            </div>
        </div>;
    
    return (
        <div className="ebusinesscard-page">
            <div className="container">
                <div className="top-bar">
                    <div className="search-bar">
                        <input type="text" placeholder="Search agent..." id="search-input" onChange={e => handleSearch(e.target.value)} />
                        <button onClick={clearField}>Reset</button>
                    </div>
                    <div className="filter-buttons">
                        <button className={salesactive ? "active" : ""} onClick={e => filterAgent('Sales')}>Sales</button>
                        <button className={rentactive ? "active" : ""} onClick={e => filterAgent('Rental')}>Property Management</button>
                    </div>
                </div>
                <MemberList profiles={profiles} />
            </div>            
        </div>
    )
}
export default Homepage;