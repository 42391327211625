import React from "react";

export default function MyListing() {
    return (
        <div className="listing-page">
            <div className="container view-listing-container">
                <div className="view-listing-tile">
                    <div className="background-wrapper">
                        <h3>VIEW OUR PROPERTIES <br/> FOR <span>SALE</span></h3>
                        <p className="subtitle">Scan to explore all properties we have on the market for sale.</p>
                        <a target="_blank" href="https://www.melcorprealestate.com.au/?action=epl_search&post_type=property&property_status=current">
                            <img src="https://chart.googleapis.com/chart?cht=qr&chs=300x300&chld=M|0&chl=https://www.melcorprealestate.com.au/?action=epl_search%26post_type=property%26property_status=current" 
                                className="qr-code img-fluid img-responsive" 
                                align="absmiddle" 
                                vspace="5" 
                                hspace="5" />
                        </a>
                    </div>
                </div>
                <div className="view-listing-tile">
                    <div className="background-wrapper">
                        <h3>VIEW OUR PROPERTIES <br/> FOR <span>RENT</span></h3>
                        <p className="subtitle">Scan to explore all properties we have on the market for rent.</p>
                        <a target="_blank" href="https://www.melcorprealestate.com.au/?action=epl_search&post_type=rental&property_status=current">
                            <img src="https://chart.googleapis.com/chart?cht=qr&chs=300x300&chld=M|0&chl=https://www.melcorprealestate.com.au/?action=epl_search%26post_type=rental%26property_status=current" 
                                className="qr-code img-fluid img-responsive" 
                                align="absmiddle" 
                                vspace="5" 
                                hspace="5" />
                        </a> 
                    </div>
                </div>
            </div>
        </div>
    )
}