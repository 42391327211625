import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Homepage";
import MemberDetail from "./pages/MemberDetailPage";
import ErrorPage from "./pages/ErrorPage";
import Layout from "./pages/Layout";
import Layout2 from "./pages/Layout2";
import "./styles/main.scss";
import MyListing from "./components/MyListing";
import MyForm from "./components/MyForm";


class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route index element={<HomePage />}/>
            <Route path="/view-properties/" element={<MyListing />}/>
            <Route path="/forms/" element={<MyForm />}/>
            <Route path="*" element={<ErrorPage />} />
          </Route>
          <Route path="/" element={<Layout2 />} >
            <Route path="member-detail/:slug" element={<MemberDetail />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}
export default App;
  